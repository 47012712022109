:root {
  --why-choose-us-text-height: 80px;
}

.container {
  background: var(--dark-blue-700);
}

.content {
  padding: 24px;
  position: relative;
  overflow: hidden;
}

.leftGameCube,
.rightGameCube {
  position: absolute;
  z-index: 1;
  width: 240px;
  height: 240px;
}

.leftGameCube {
  top: 0;
  left: 0;
  transform: translate(-25%, -25%);
}

.rightGameCube {
  right: 0;
  bottom: 0;
  transform: translate(25%, 25%);
}

.overlay {
  position: absolute;
  z-index: 2;
  background: #193464cf;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.imageContainer {
  min-width: 0;
  max-width: 100%;
  align-self: self-start;
}

.image {
  width: 100%;
}

.contentBlock {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--light-blue-100);
}

.knowMore {
  text-align: left;
  color: var(--white);
}

.whyChooseUsText {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  left: 0;
  max-height: unset;
}

.contentBlockBody {
  z-index: 2;
  min-width: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contentBlockBodyTitle {
  font-family: "Gilroy", sans-serif;
  font-size: 37px;
  line-height: 45px;
  font-weight: 800;
  margin: 0;
  text-align: left;
  color: var(--white);
}

.contentBlockBodyDescription {
  line-height: 34px;
  margin: 0;
}

.list {
  color: var(--light-blue-100);
  gap: 20px;
}

.image {
  max-height: 320px;
}

@media (min-width: 480px) {
  .imageContainer {
    max-width: 70%;
  }
}

@media (min-width: 768px) {
  .image {
    max-height: unset;
  }

  .contentBlockBodyTitle {
    font-size: 64px;
    line-height: 64px;
  }

  .whyChooseUsText {
    width: calc(100% - 150px);
    left: 150px;
    max-height: var(--why-choose-us-text-height);
  }

  .contentBlock {
    flex-direction: row;
  }

  .contentBlockBody {
    max-width: 50%;
    margin-top: calc(var(--why-choose-us-text-height) - 18px);
  }

  .imageContainer {
    max-width: 50%;
    align-self: center;
  }
}

@media (min-width: 1024px) {
  .content {
    padding: 64px;
  }

  .contentBlock {
    gap: 20px;
  }
}
