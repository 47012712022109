:root {
  --content-padding: 20px;
  --title-max-height: 86px;
}

.container {
  display: flex;
  justify-content: center;
}

.containerTitle {
  position: absolute;
  max-height: var(--title-max-height);
  z-index: 1;

  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: calc(100% - 100px);
}

.content {
  position: relative;
  text-align: center;
  color: var(--blue-grey-200);
  display: flex;
  gap: 20px;
  padding: 20px;
  flex-direction: column;
}

.contentImageBlock {
  flex: 1;
  align-self: auto;
}

.contentImage {
  width: 100%;
  max-height: 280px;
}

.contentTitle {
  font-family: "Gilroy", sans-serif;
  font-size: 37px;
  line-height: 42px;
  font-weight: 800;
  color: var(--blue-800);
  margin: 0;
}

.contentDescription {
  line-height: 34px;
}

.contentBody {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  flex: 1;
}

.knowMore {
  color: var(--blue-grey-200);
}

.getStarted {
  position: relative;
}

.getStartedInner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 16px;
  background: rgb(245, 246, 246, 0.7);
  flex-wrap: wrap;
  gap: 16px;
}

.getStartedDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 39px;
  text-align: center;
  margin: 0;
  color: var(--blue-grey-200);
  max-width: 68%;
}

.getStartedGameCube {
  position: absolute;
  max-height: 120px;
  top: 16px;
  transform: none;
  height: 80%;
  left: 0;
  width: fit-content;
  z-index: 0;
}

.letsConnect {
  padding: 16px 32px;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
}

@media (min-width: 768px) {
  .contentImageBlock {
    flex: 1.6;
    align-self: center;
  }

  .contentImage {
    max-height: unset;
  }

  .contentTitle {
    font-size: 48px;
    line-height: 64px;
  }
}

@media (min-width: 1024px) {
  .container {
    justify-content: unset;
  }

  .containerTitle {
    top: var(--content-padding);
    left: 100px;
    width: calc(100% - 100px);
    transform: none;
  }

  .content {
    flex-direction: row;
    padding: var(--content-padding) var(--content-padding) var(--content-padding) 0;
  }

  .getStartedDescription {
    font-size: 22px;
    font-weight: 400;
  }

  .letsConnect {
    font-size: 20px;
    line-height: 28px;
  }

  .getStartedInner {
    padding: 32px;
  }

  .contentBody {
    margin-top: calc(var(--title-max-height) - 18px);
  }
}
