.container {
  width: 100%;
  background-image: url("../../assets/patternBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 24px 0;
}

.formContainer {
  margin: 32px auto;
  max-width: 640px;
}

.form {
  padding: 24px;
  background: var(--white);
  color: var(--blue-grey-200);
}

.formWelcome {
  font-size: 16px;
  font-weight: 700;
  line-height: 8px;
  letter-spacing: 0;
}

.formCurve path {
  fill: var(--blue-grey-200);
}

.formTitle {
  font-family: "Gilroy", sans-serif;
  font-size: 37px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 0;
  margin: 8px 0;
  color: var(--blue-800);
}

.formSubmit {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  border-radius: 0;
  padding: 10px 64px;
  margin: 0 auto;
  display: block;
  background: var(--blue-700);
}

.formInputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
}

.formInput {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.usernameBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.successResult {
  background: var(--white);
  min-width: 280px;
  max-width: 420px;
  width: 100%;
}

.smileIcon {
  width: 64px;
  height: 64px;
  fill: var(--blue-800);
}

@media (min-width: 768px) {
  .usernameBlock {
    flex-direction: row;
  }

  .firstName,
  .lastName {
    flex: 1;
  }
}
