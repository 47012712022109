.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: var(--shadow-grey);
  display: flex;
  align-items: center;
  justify-content: center;
}
