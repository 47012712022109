.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 4px;
  border-radius: 4px;
}

.closeIcon:hover,
.closeIcon:focus {
  background: var(--grey-100);
}

.closeIcon:active {
  background: var(--shadow-grey);
}
