:root {
  --padding: 16px;
}

.container {
  position: relative;
}

.disabled {
  opacity: 0.5;
}

.label {
  position: absolute;
  top: 50%;
  left: var(--padding);
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.1s;
  z-index: 1;
  display: block;
  background: var(--white);
  padding: 2px;
}

.input {
  width: 100%;
  border-radius: 2px;
  padding: var(--padding);
  outline: none;
  background: none;
  border: 0.5px solid var(--blue-grey-50);
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  /* -webkit-text-fill-color: white !important; */
  transition: background-color 5000s ease-in-out 0s;
}

.input:focus + .label,
.input:-webkit-autofill + .label,
.activeLabel {
  top: 0;
  font-size: 12px;
  opacity: 1;
}

.input:focus + .label {
  color: var(--blue-800);
}

.input:focus {
  border: 0.5px solid var(--blue-800);
}

.inputErrorHighlight,
.inputErrorHighlight:focus {
  border: 0.5px solid var(--red-800);
}

.inputErrorHighlight:focus + .label,
.inputErrorHighlight + .activeLabel {
  color: var(--red-800);
}

.visibleControlButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--padding);
  cursor: pointer;
}

.textarea {
  resize: none;
}
