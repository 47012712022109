:root {
  --footer-height: 56px;
  --header-height: 125px;
}

.title {
  user-select: none;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.header {
  background: #fff;
  gap: 20px;
  padding: 20px;
}

.headerMenu {
  gap: 20px;
}

.headerEndContent {
  gap: 20px;
}

.headerMenu {
  display: none;
}

.body {
  width: 100%;
  background: var(--white);
  flex: 1;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.logoIcon {
  width: 100%;
}

.profileButton {
  cursor: pointer;
  padding: 4px 8px;
  display: flex;
  gap: 8px;
}

.userProfileDropDown {
  display: flex;
  flex-direction: column;
  background: var(--white);
  min-width: 180px;
  min-height: 100px;
  justify-content: space-between;
  box-shadow: 0 0 4px lightgrey;
  border-radius: 5px;
}

.userProfileDropDownItem {
  padding: 8px;
  width: 100%;
  display: block;
  border-bottom: 1px solid lightgrey;
}

.userProfileDropDownItem:hover {
  background: rgba(0, 0, 0, 0.05);
}

.menuItem {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: var(--black);
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.headerEndContentBtn {
  font-size: 14px;
  line-height: 19px;
  padding: 8px;
  letter-spacing: 0;
  text-align: center;
}

.headerEndContentBtnInner {
  display: flex;
  align-items: center;
  gap: 4px;
}

.headerEndContentBtnIcon {
  width: 14px;
  height: 14px;
}

.servicesIcon {
  width: 12px;
  height: 12px;
}

.mobileMenu {
  outline: none;
  border: none;
  background: none;
  display: block;
  cursor: pointer;
}

@media (min-width: 768px) {
  .header {
    padding: 20px 32px;
  }

  .logoIcon {
    max-height: 64px;
  }
}

.completeRegistrationResult {
  background: var(--white);
  z-index: 1;
}

.link {
  color: var(--blue-800);
}

.completeRegistrationIcon {
  fill: var(--blue-800);
  height: 64px;
}

/*  */
@media (min-width: 920px) {
  .header {
    height: var(--header-height);
  }

  .mobileMenu {
    display: none;
  }

  .headerMenu {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .header {
    padding: 0 124px;
  }

  .headerEndContentBtn {
    font-size: 20px;
    line-height: 28px;
    padding: 12px 32px;
  }
}
