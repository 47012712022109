.banner {
  background-image: url("../../assets/joinUs/joinUsBannerBg.png");
}

.container {
  width: 100%;
  background-image: url("../../assets/patternBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 24px 0;
}

.contentBanner {
  margin: 0 24px 24px;
  padding: 24px 24px 0;
  background: var(--light-blue-75);
}

.contentBannerImage {
  width: 100%;
}

.contentDescription {
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  margin: 0;
  margin-bottom: 18px;
  color: var(--blue-grey-200);
}

.form {
  padding: 24px;
  background: var(--white);
  color: var(--blue-grey-200);
}

.formWelcome {
  font-size: 16px;
  font-weight: 700;
  line-height: 8px;
  letter-spacing: 0;
}

.formCurve path {
  fill: var(--blue-grey-200);
}

.formTitle {
  font-family: "Gilroy", sans-serif;
  font-size: 37px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 0;
  margin: 8px 0;
  color: var(--blue-800);
}

.formSubmit {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  border-radius: 0;
  padding: 10px 64px;
  margin: 0 auto;
  display: block;
  background: var(--blue-700);
}

.formInputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
}

.formInput {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.usernameBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.successResult {
  background: var(--white);
}

@media (min-width: 768px) {
  .section {
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 0 20px;
  }

  .contentBanner {
    margin: 0;
    padding: 24px 24px 0;
    max-width: 320px;
  }
}

@media (min-width: 1024px) {
  .usernameBlock {
    flex-direction: row;
  }

  .formContainer {
    position: relative;
    flex: 1;
    max-width: 600px;
  }

  .form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 48px;
  }

  .firstName,
  .lastName {
    flex: 1;
  }
}
