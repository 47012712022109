.banner {
  background-image: url("../../assets/whatWeDo/bannerBg.png");
}

.containerTitle {
  font-family: "Gilroy", sans-serif;
  font-weight: 800;
  letter-spacing: 0;
  font-size: 37px;
  line-height: 45px;
  margin: 16px 0;
}

.container {
  color: var(--blue-grey-200);
  background-image: url("../../assets/patternBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.containerDescription {
  line-height: 30px;
  letter-spacing: 0;
  margin-bottom: 0;
}

.containerTitle {
  color: var(--blue-800);
}

.containerHeader {
  text-align: start;
  padding: 64px 20px 0;
  margin: 0 20px;
}

.containerBody {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  padding: 20px;
}

.knowMore {
  color: var(--blue-grey-200);
}

.footer {
  width: 100%;
  background-image: url("../../assets/whatWeDo/whatWeDoFooterBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footerInnerContainer {
  display: flex;
  flex-direction: column;
  color: var(--white);
  background: #1b6de07d;
  overflow: hidden;
}

.footerTitle,
.footerDescription {
  font-family: "Gilroy", sans-serif;
  margin: 0;
}

.footerBody {
  max-width: 100%;
  padding: 20px;
}

.footerTitle {
  margin-bottom: 16px;
  font-weight: 800;
  font-size: 37px;
  line-height: 45px;
}

.footerDescription {
  font-size: 26px;
  line-height: 46px;
  font-weight: 500;
}

.footerImageContainer {
  width: 415px;
  position: relative;
  overflow-x: clip;
  align-self: flex-end;
}

.footerImage {
  display: block;
}

@media (min-width: 768px) {
  .footerInnerContainer {
    flex-direction: row;
    overflow: unset;
  }

  .footerTitle {
    font-size: 64px;
    line-height: 64px;
  }

  .footerImageContainer {
    align-self: unset;
  }

  .footerDescription {
    font-size: 26px;
    line-height: 46px;
  }

  .containerHeader {
    max-width: 95%;
    text-align: center;
  }

  .containerTitle {
    font-size: 60px;
    line-height: 64px;
  }

  .containerBody {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 64px;
    grid-column-gap: 64px;
    padding: 0 64px 64px;
  }

  .containerHeader {
    padding: 64px;
  }

  .footerBody {
    max-width: 60%;
    margin: 64px;
    margin-right: 0;
    min-width: 0;
  }

  .footerImage {
    position: absolute;
    bottom: 0;
  }
}
