.container {
  width: 100%;
  background-image: url("../../../assets/patternBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.innerContainer {
  padding: 50px 20px 20px;
  color: var(--blue-grey-200);
}

.header {
  text-align: center;
  margin: 0 auto 20px auto;
}

.knowMore {
  color: var(--blue-grey-200);
}

.headerImage {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 80%;
}

.headerTitle {
  margin: 0;
  font-family: "Gilroy", sans-serif;
  font-weight: 800;
  letter-spacing: 0;
  color: var(--blue-800);
  font-size: 37px;
  line-height: 45px;
}

.headerDescription {
  line-height: 34px;
  margin: 20px 0;
}

.content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  gap: 16px;
  border: 1px solid var(--grey-50);
  box-shadow: 1px 1px 4px 0 var(--shadow-grey);
}

.cardTitle {
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;
  margin: 0;
}

.cardDescription {
  line-height: 34px;
  margin-bottom: 0;
}

.cardReadMore {
  color: var(--blue-800);
  line-height: 34px;
  cursor: pointer;
}

.cardImageWrapper {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: var(--light-blue-50);
  margin-bottom: 16px;
}

.cardImage {
  width: 32px;
  height: 32px;
}

@media (min-width: 768px) {
  .content {
    grid-template-columns: repeat(2, 1fr);
  }

  .header {
    max-width: 50%;
  }

  .headerTitle {
    font-size: 60px;
    line-height: 64px;
  }

  .cardImageWrapper {
    width: 120px;
    height: 120px;
  }

  .cardImage {
    width: 86px;
    height: 86px;
  }

  .cardTitle {
    font-size: 26px;
    line-height: 34px;
  }
}

@media (min-width: 1200px) {
  .content {
    grid-template-columns: repeat(3, 1fr);
  }
}
