@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/dmSans/DMSans-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/dmSans/DMSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/dmSans/DMSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/dmSans/DMSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/dmSans/DMSans-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/dmSans/DMSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/dmSans/DMSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "DM Sans";
  src: url("./assets/fonts/dmSans/DMSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/gilroy/gilroyLight.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/gilroy/gilroyExtraBold.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./assets/fonts/sfProText/SF-Pro-Text-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./assets/fonts/sfProText/SF-Pro-Text-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./assets/fonts/sfProText/SF-Pro-Text-Semibold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./assets/fonts/sfProText/SF-Pro-Text-Bold.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./assets/fonts/sfProText/SF-Pro-Text-Heavy.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("./assets/fonts/sourceSerifPro/SourceSerif4-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("./assets/fonts/sourceSerifPro/SourceSerif4-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("./assets/fonts/sourceSerifPro/SourceSerif4-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("./assets/fonts/sourceSerifPro/SourceSerif4-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("./assets/fonts/sourceSerifPro/SourceSerif4-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("./assets/fonts/sourceSerifPro/SourceSerif4-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("./assets/fonts/sourceSerifPro/SourceSerif4-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
