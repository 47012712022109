.banner {
  background-image: url("../../assets/aboutUs/aboutUsBanner.png");
}

.section,
.reverseSection {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 30vh;
}

.section {
  color: var(--white);
  background-image: url("../../assets/aboutUs/sectionBg.png");
}

.reverseSection {
  color: var(--blue-grey-200);
  background-image: url("../../assets/patternBg.png");
}

.sectionBody {
  background-color: var(--dark-blue-900);
}

.reverseSectionBody {
  background-color: var(--grey-100);
}
