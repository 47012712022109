.container {
  background-image: url("../../../assets/footer.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
}

.innerContainer {
  background: #193464cf;
  width: 100%;
  height: 100%;
}

.section {
  padding: 24px;
  color: var(--light-blue-100);
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.link {
  color: var(--light-blue-100);
}

.contentBlock {
  display: flex;
  flex-direction: column;
  min-width: 20%;
}

.contentBlockTitle {
  font-family: "Gilroy", sans-serif;
  font-size: 26px;
  font-weight: 800;
  line-height: 70px;
  letter-spacing: 0;
  color: var(--white);
  position: relative;
}

.contentBlockTitle:after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 80px;
  height: 4px;
  background: var(--blue-800);
}

.contentBlockItem {
  margin: 8px 0;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background: var(--light-blue-100);
}

.contactItem {
  gap: 8px;
}

.socials {
  gap: 16px;
}

.followUs {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
}

.contactIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.contactItem:hover .contactIcon path {
  fill: var(--light-blue-500);
}

.contactItem:active .contactIcon path {
  fill: var(--blue-800);
}

.contactItem:focus .contactIcon path {
  text-decoration: none;
  outline: 0;
}

.allRights {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 24px;
  background: var(--blue-800);
  color: var(--white);
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 1px;
  text-align: center;
  flex-wrap: wrap;
}

.allRightsLink {
  color: var(--white);
  text-decoration: none;
  display: block;
  flex: none;
  width: 100%;
  text-align: center;
}

.allRightsCompanyName {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 1px;
  text-align: left;
}

.allRightsDividerLine {
  display: none;
}

@media (min-width: 768px) {
  .section {
    padding: 64px;
    gap: 16px;
  }

  .allRightsDividerLine {
    display: block;
  }

  .allRightsLink {
    display: unset;
    width: unset;
    text-align: unset;
  }
}

@media (min-width: 920px) {
  .section {
    flex-direction: row;
  }

  .contentBlockItem {
    margin: 16px 0;
  }
}

@media (min-width: 1200px) {
  .section {
    padding: 80px 128px;
  }
}
