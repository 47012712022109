.welcomeBanner {
  padding: 32px;
  width: 100%;
  background-image: url("../../assets/home/bannerBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
}

.heading {
  min-width: 280px;
  max-width: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.welcomeText {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
}

.headingTitle {
  font-family: "Gilroy", sans-serif;
  font-weight: 800;
  font-size: 40px;
  line-height: 45px;
  letter-spacing: 0;
  text-align: center;
}

.subHeadingTitle {
  margin: 32px 0;
  text-align: center;
}

.getStarted {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 22px;
  padding: 16px 24px;
  font-weight: 600;
  letter-spacing: 0;
}

.clientsStatistic {
  color: var(--white);
  background: var(--dark-blue-700);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px;
  flex-direction: column;
}

.clientsStatisticItem {
  position: relative;
}

.statisticItemLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  white-space: nowrap;
}

.statisticItemValue {
  font-family: "Source Serif Pro", sans-serif;
  font-size: 86px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0;
  color: var(--dark-blue-600);
}

.clientsStatisticBorderLine {
  margin: 0 auto;
  max-width: 80%;
  min-width: 100px;
  height: 1px;
  border-radius: 2px;
  background: var(--dark-blue-450);
}

@media (min-width: 768px) {
  .welcomeBanner {
    padding: 128px;
  }

  .headingTitle {
    font-size: 48px;
    line-height: 64px;
  }

  .clientsStatistic {
    padding: 48px;
  }

  .getStarted {
    font-size: 20px;
    line-height: 28px;
    padding: 16px 32px;
  }
}

@media (min-width: 1024px) {
  .headingTitle {
    font-size: 60px;
    line-height: 45px;
  }

  .clientsStatisticBorderLine {
    display: none;
  }

  .clientsStatistic {
    flex-direction: row;
    gap: 84px;
  }
}
