@import "variables.css";
@import "fonts.css";

* {
  box-sizing: border-box;
  font-family: "SF Pro Text", sans-serif;
  letter-spacing: 0;
  font-weight: 400;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.formContainer {
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  position: relative;
}

.formTitle {
  text-align: center;
}

.formInputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formButton {
  width: fit-content;
}

.primaryButton {
}
