:root {
  --top-height: 65px;
}

.homePageTopContainer {
  background: var(--blue-800);
  overflow: hidden;
}

.socials {
  align-items: center;
  gap: 16px;
  display: none;
}

.contacts {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
}

.contactItem {
  color: currentColor;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  gap: 8px;
}

.contactContainer:hover .contactItemIcon path {
  fill: var(--light-blue-500);
}

.contactContainer:active .contactItemIcon path {
  fill: var(--dark-blue-700);
}

.contactContainer:active {
  color: var(--dark-blue-700);
}

.contactContainer:focus .contactItemIcon path {
  text-decoration: none;
  outline: 0;
}

.contactItemLink,
.contactItemLink:hover {
  color: var(--white);
}

.contactItemIcon {
  width: 18px;
  height: 18px;
}

.leftIcon,
.rightIcon {
  position: absolute;
  top: 0;
}

.leftIcon {
  left: 0;
}

.rightIcon {
  right: 0;
}

@media (min-width: 768px) {
  .socials {
    display: flex;
  }

  .homePageTopContainer {
    height: var(--top-height);
  }

  .contacts {
    gap: 32px;
    flex-direction: row;
    padding: unset;
  }

  .contactItem {
    line-height: 40px;
  }

  .contactItemIcon {
    width: 24px;
    height: 24px;
  }
}
