.card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 1px 1px 4px 0 var(--shadow-grey);
  padding: 20px;
  border-radius: 4px;
}

.icon {
  width: 48px;
  height: 48px;
}

.iconContainer {
  background: var(--light-blue-50);
  border-radius: 4px;
  padding: 4px;
  box-shadow: 1px 1px 4px 0 var(--shadow-grey);
  height: fit-content;
  width: fit-content;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title,
.description {
  margin: 0;
}

.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
}

.description {
  line-height: 30px;
}

@media (min-width: 768px) {
  .card {
    box-shadow: none;
    padding: 0;
  }

  .title {
    font-size: 26px;
  }

  .icon {
    width: unset;
    height: unset;
  }

  .iconContainer {
    padding: 12px;
  }
}

@media (min-width: 1024px) {
  .card {
    flex-direction: row;
  }

  .iconContainer {
    background: var(--white);
  }
}
