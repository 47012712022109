.fileItemPrefix {
  margin-right: 8px;
}

.statusDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin-left: 8px;
}

.noStatus {
  background: grey;
}

.confirmed {
  background: green;
}

.missing {
  background: red;
}

.underReview {
  background: yellow;
}

.profileContainer {
  max-width: 420px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
}

.documentManagerBlock {
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 16px;
}

.documentManagerBlock:first-child {
  border-top: 1px solid #c4c4c4;
}
