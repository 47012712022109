.social {
  cursor: pointer;
}

.social:hover path {
  fill: var(--light-blue-500);
}

.social:active path {
  fill: var(--dark-blue-700);
}

.social:focus path {
  text-decoration: none;
  outline: 0;
}
