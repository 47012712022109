:root {
  --white: #ffffff;
  --black: #000000;

  --grey-50: #f1f4f6;
  --grey-75: #f5f6f6;
  --grey-100: #ededee;

  --shadow-grey: #00000040;

  --blue-grey-50: #8fa0b7;
  --blue-grey-200: #5d6774;

  --light-blue-50: #eef3fb;
  --light-blue-75: #dbe3ed;
  --light-blue-100: #c5d6f4;
  --light-blue-500: #69b1ff;

  --blue-700: #228af6;
  --blue-800: #1173ff;

  --dark-blue-450: #2458b2;
  --dark-blue-500: #2a5299;
  --dark-blue-600: #1d407d;
  --dark-blue-700: #193464;
  --dark-blue-900: #152e5a;

  --red-800: #ce3636;
}
