.btn {
  position: absolute;
  left: 0;
  box-shadow: 0 0 2px var(--light-blue-500);
  border: none;
  outline: none;
}

.btnIcon {
  color: var(--blue-800);
}
