.container {
  width: 100%;
  background-image: url("../../assets/patternBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sectionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  padding: 20px;
}

.whoWeAreBannerContainer {
  width: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  /* box-shadow: 1px 1px 4px 0 var(--shadow-grey); */
  flex: none;
  min-width: 0;
  max-width: 100%;
  max-height: 240px;
}

.whoWeAreBanner,
.whoWeAreMobileBanner {
  height: 100%;
  width: 100%;
}

.whoWeAreBanner {
  display: none;
}

.whoWeAreMobileBanner {
  display: block;
}

.whoWeAreOverlay {
  background: rgba(16, 77, 163, 0.64);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: none;
}

.bannerLeftCube,
.bannerRightCube,
.whoWeAreText,
.whoWeAreTextMobile {
  position: absolute;
  z-index: 1;
}

.bannerLeftCube,
.bannerRightCube {
  display: none;
}

.bannerLeftCube {
  bottom: 0;
  left: 0;
  transform: translate(-54%, 16%);
}

.bannerRightCube {
  top: 0;
  right: 0;
  transform: translate(62%, -10%);
}

.whoWeAreText,
.whoWeAreTextMobile {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
}

.whoWeAreText {
  height: 94%;
  display: none;
}

.whoWeAreTextMobile {
  width: 94%;
  display: block;
}

.whoWeAreBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
}

.content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: var(--blue-grey-200);
}

.knowMore {
  color: var(--blue-grey-200);
}

.whoWeAreTitle {
  font-family: "Gilroy", sans-serif;
  font-size: 37px;
  line-height: 45px;
  font-weight: 800;
  text-align: center;
  color: var(--blue-800);
  margin: 0;
}

.whoWeAreDescription {
  text-align: center;
  margin: 20px 0;
}

.knowMore {
  text-align: center;
  color: var(--blue-grey-200);
}

.contentItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contentItem {
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 1px 1px 4px 0 var(--shadow-grey);
  padding: 20px;
  border-radius: 4px;
}

.itemImageContainer {
  border-radius: 4px;
  background: var(--light-blue-50);
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.contentItemTitle {
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
  margin: 0;
}

.contentItemDescription {
  line-height: 30px;
  margin: 0;
}

@media (min-width: 768px) {
  .sectionContainer {
    padding: 64px;
  }

  .whoWeAreBannerContainer {
    max-height: 320px;
  }

  .contentItems {
    flex-direction: row;
  }

  .whoWeAreTitle {
    font-size: 60px;
    line-height: 64px;
  }
}

@media (min-width: 1024px) {
  .sectionContainer {
    flex-direction: row;
  }

  .whoWeAreOverlay {
    display: block;
  }

  .whoWeAreText {
    display: block;
  }

  .whoWeAreTextMobile {
    display: none;
  }

  .bannerLeftCube,
  .bannerRightCube {
    display: block;
  }

  .whoWeAreBanner {
    display: block;
  }

  .whoWeAreMobileBanner {
    display: none;
  }

  .whoWeAreBannerContainer {
    min-width: 240px;
    max-width: 320px;
    max-height: calc(100% - 200px);
  }
}
