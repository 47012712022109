.banner {
  width: 100%;
  background-image: url("../../assets/whatWeDo/bannerBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
  padding: 24px;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bannerWelcome {
  font-family: "SF Pro Text", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0;
}

.bannerTitle {
  font-family: "Gilroy", sans-serif;
  font-size: 40px;
  line-height: 45px;
  letter-spacing: 0;
  margin: 0;
  font-weight: 800;
}

.bannerDescription {
  line-height: 34px;
}

@media (min-width: 768px) {
  .banner {
    padding: 100px;
    display: block;
    text-align: unset;
  }

  .bannerTitle {
    font-size: 60px;
    line-height: 64px;
  }
}
