.knowMore {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  color: currentColor;
}

.knowMoreIcon path {
  fill: currentColor;
}
