.link {
  color: var(--white);
  text-decoration: none;
  outline: none;
  transition: none;
}

.link:hover {
  color: var(--light-blue-500);
}

.link:active {
  color: var(--blue-800);
}

.link:focus {
  text-decoration: none;
  outline: 0;
}
