.list {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.listItemSignDot {
  background: currentColor;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: block;
}

.listItemSignNone {
  display: none;
}
