.button {
  outline: none;
  border: none;
  padding: 12px 32px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.button:focus {
  outline: 0;
}

.primary {
  background: var(--blue-800);
  color: var(--white);
}

.primary:hover {
  background: var(--dark-blue-450);
}

.primary:active {
  background: var(--dark-blue-600);
}

.secondary {
  background: var(--white);
  color: var(--blue-800);
}

.secondary:hover {
  background: var(--grey-100);
}

.secondary:active {
  background: var(--light-blue-50);
}
