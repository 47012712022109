.section {
  display: flex;
  padding: 24px;
  gap: 32px;
  color: currentColor;
  flex-direction: column;
  overflow: hidden;
}

.aboutUsTitle {
  font-size: 16px;
  font-weight: 700;
}

.curve path {
  fill: currentColor;
}

.body {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  position: relative;
  border-radius: 4px;
}

.reverseSection .imageContainer {
  display: flex;
  justify-content: flex-end;
}

.bodyImage {
  position: absolute;
  z-index: 0;
  bottom: 22px;
}

.mobileImage {
  display: block;
  max-width: 100%;
  min-width: 0;
}

.image {
  display: none;
}

.bodyImageCube {
  position: absolute;
  width: 120px;
  height: 120px;
  left: 50%;
  top: 50%;
  transform: translate(-200%, -50%);
  z-index: 1;
  opacity: 0.2;
}

.titleContainer,
.description {
  position: relative;
  z-index: 1;
}

.title {
  font-family: "Gilroy", sans-serif;
  font-size: 37px;
  line-height: 45px;
  font-weight: 800;
  margin: 16px 0;
}

.titleContainer {
  flex: 1;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  flex: 1;
  min-width: 250px;
}

@media (min-width: 768px) {
  .section {
    padding: 64px;
  }

  .title {
    font-size: 50px;
    line-height: 52px;
  }
}

@media (min-width: 1024px) {
  .reverseSection .imageContainer {
    display: block;
  }

  .mobileImage {
    display: none;
  }

  .image {
    display: block;
  }

  .section {
    flex-direction: row;
  }

  .reverseSection {
    flex-direction: row-reverse;
  }
}
